<template>
  <div>
    <video :src="video.video_url" muted controls style="width: 100%; height: 100%; object-fit: fill"></video>
    <div class="title">{{ video.title }}</div>
    <div class="published_time">发布时间 {{ dateFormat(video.published_time) }}</div>
    <div class="intro">
      {{ video.intro }}</div>
  </div>
</template>
<script setup>
import { useRoute } from 'vue-router'
import { reactive, onMounted, ref } from 'vue'
import axiosIns from '@/libs/axios'
import flvjs from 'flv.js'

const route = useRoute()
const video = reactive({
  id: 0,
  title: '',
  video_url: '',
  published_time: '',
  intro: ''
})

const flvPlayer = ref(null)
const videoElement = ref()

const dateFormat = v => new Date(v).toLocaleString()

const getVideo = async () => {
  const data = await axiosIns.get('/videos/get', { params: { id: video.id } })
  Object.assign(video, data)
//   // console.log('video >> ', video)
//   if (flvjs.isSupported()) {
//     console.log('进入这里1')
//   if (videoElement.value) {
//     // console.log('进入这里2')
//     flvPlayer.value = flvjs.createPlayer({
//       type: 'flv',
//       url: video.video_url
//     })
//     console.log('video', flvPlayer.value)
//     flvPlayer.value.attachMediaElement(videoElement.value)
//     flvPlayer.value.load()
//     flvPlayer.value.play()
//   }
// }
}

onMounted(() => {
  video.id = route.query.id
  getVideo()
})

</script>
<style lang="less" scoped>
.title {
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 550;
  margin-top: 20px;
}

.published_time {
  font-size: 14px;
  color: #999;
  margin-bottom: 10px;
}

.intro {
  text-align: justify;
  margin-bottom: 20px;
}
</style>
